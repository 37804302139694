/*!
 * KBarber v1.0 | (c) 2024 Kaotic
 */

(function ($) {
    "use strict";

    $(window).on('load', function () {
        $('body').addClass('loaded');
    });

    /*=========================================================================
        Sticky Header
    =========================================================================*/
    $("#header").after('<div class="header-height"></div>');
    function headerHeight() {
        var height = $("#header").height();
        $('.header-height').css('height', height + 'px');
    }
    $(function () {
        var header = $("#header"),
            yOffset = 0,
            triggerPoint = 80;
        headerHeight();
        $(window).resize(headerHeight);
        $(window).on('scroll', function () {
            yOffset = $(window).scrollTop();

            if (yOffset >= triggerPoint) {
                header.addClass("navbar-fixed-top animated slideInDown");
            } else {
                header.removeClass("navbar-fixed-top animated slideInDown");
            }

        });
    });

    /*=========================================================================
        Mobile Menu
    =========================================================================*/
    $('.menu-wrap ul.nav').slicknav({
        prependTo: '.header-section .navbar',
        label: '',
        allowParentLinks: true
    });

    /*=========================================================================
        Main Slider
    =========================================================================*/
    $('#main-slider').owlCarousel({
        loop: true,
        autoplay: true,
        smartSpeed: 500,
        items: 1,
        nav: false,
        navText: ['<i class="arrow_carrot-left"></i>', '<i class="arrow_carrot-right"></i>'],
    });

    $("#main-slider").on("translate.owl.carousel", function () {
        $(".main_slide .slider_content h3").removeClass("animated fadeInUp").css("opacity", "0");
        $(".main_slide .slider_content h1").removeClass("animated fadeInUp").css("opacity", "0");
        $(".main_slide .slider_content p, .main_slide .slider_content .default_btn").removeClass("animated fadeInUp").css("opacity", "0");
    });

    $("#main-slider").on("translated.owl.carousel", function () {
        $(".main_slide .slider_content h3").addClass("animated fadeInUp").css("opacity", "1");
        $(".main_slide .slider_content h1").addClass("animated fadeInUp").css("opacity", "1");
        $(".main_slide .slider_content p, .main_slide .slider_content .default_btn").addClass("animated fadeInUp").css("opacity", "1");
    });

    /*=========================================================================
        Gallery Slider
    =========================================================================*/
    $('#gallery-slide').owlCarousel({
        loop: true,
        autoplay: true,
        smartSpeed: 500,
        items: 1,
        dots: false,
        nav: true,
        navText: ['<i class="arrow_carrot-left"></i>', '<i class="arrow_carrot-right"></i>']
    });

    /*=========================================================================
        Isotope Active
    =========================================================================*/
    $('.portfolio_items').imagesLoaded(function () {

        // Add isotope click function
        $('.gallery_filter li').on('click', function () {
            $(".gallery_filter li").removeClass("active");
            $(this).addClass("active");

            var selector = $(this).attr('data-filter');
            $(".portfolio_items").isotope({
                filter: selector,
                animationOptions: {
                    duration: 750,
                    easing: 'linear',
                    queue: false,
                }
            });
            return false;
        });

        $(".portfolio_items").isotope({
            itemSelector: '.single_item',
            layoutMode: 'fitRows',
        });
    });

    /*=========================================================================
        Initialize smoothscroll plugin
    =========================================================================*/
    // const scroll = new SmoothScroll('a[href*="#"]', {
    //     speed: 1000,
    //     offset: 60
    // });

    /*=========================================================================
        Testimonial Carousel
    =========================================================================*/
    $('#testimonial_carousel').owlCarousel({
        loop: true,
        autoplay: true,
        smartSpeed: 500,
        items: 1,
        nav: false
    });

    /*=========================================================================
        Sponsor Carousel
    =========================================================================*/
    $('#sponsor_carousel').imagesLoaded(function () {
        $('#sponsor_carousel').owlCarousel({
            loop: true,
            margin: 10,
            autoplay: true,
            smartSpeed: 500,
            nav: false,
            dots: false,
            responsive: {
                0: {
                    items: 2
                },
                480: {
                    items: 3,
                },
                768: {
                    items: 6,
                }
            }
        });
    });

    /*=========================================================================
        Active venobox
    =========================================================================*/
    $('.img_popup').venobox({
        numeratio: true,
        infinigall: true
    });

    /*=========================================================================
        WOW Active
    =========================================================================*/
    new WOW().init();

    /*=========================================================================
        Scroll To Top
    =========================================================================*/
    $(window).on('scroll', function () {
        if ($(this).scrollTop() > 100) {
            $('#scroll-to-top').fadeIn();
        } else {
            $('#scroll-to-top').fadeOut();
        }
    });
})(jQuery);